import React from "react"
import { graphql } from "gatsby"
import { Block, Grid } from "@sonos-inc/web-ui-core"
import { useLocation } from "@reach/router"
import Layout from "../components/Layout"
import BlogpostSummary from "../components/BlogpostSummary"
import { UnstyledBlockUl } from "../components/blocks"
import { H1 } from "../components/typography"
import Label from "../components/Label"
import { defaultGridTemplateColumns } from "../constants/layout"

export default function Category({ data }) {
  const searchParams = new URLSearchParams(useLocation().search)
  const selectedCategory = searchParams.get("category")

  const { allSanityPost } = data
  const matchedPosts = allSanityPost.group.find(
    ({ fieldValue }) => fieldValue === selectedCategory
  )

  if (!matchedPosts) {
    return (
      <Layout>
        <Grid gridTemplateColumns={defaultGridTemplateColumns}>
          <Block
            mb={["8px", null, null, "16px"]}
            gridColumn={["3/27", null, "3/24", "6/27"]}
          >
            <Label upperCase>All Categories</Label>
          </Block>
        </Grid>
        <UnstyledBlockUl mb={["72px", null, "104px"]}>
          {allSanityPost.group.map(({ fieldValue, nodes, totalCount }) => (
            <Block mb={["48px", null, null, "64px"]} key={fieldValue}>
              <Grid gridTemplateColumns={defaultGridTemplateColumns}>
                <H1
                  mb={["32px", null, null, "48px"]}
                  gridColumn={["3/27", null, "3/24", "6/27"]}
                >
                  {fieldValue} ({totalCount})
                </H1>
              </Grid>
              {nodes.map((post) => (
                <BlogpostSummary key={post?.id} Element="li" {...post} />
              ))}
            </Block>
          ))}
        </UnstyledBlockUl>
      </Layout>
    )
  }

  return (
    <Layout>
      <Grid gridTemplateColumns={defaultGridTemplateColumns}>
        <Block gridColumn={["3/27", null, "3/24", "6/27"]}>
          <Label upperCase mb={["8px", null, null, "16px"]}>
            Category
          </Label>
          <H1 mb={["32px", null, null, "48px"]}>
            {selectedCategory} ({matchedPosts.nodes.length})
          </H1>
        </Block>
      </Grid>
      <UnstyledBlockUl mb={["72px", null, "104px"]}>
        {matchedPosts.nodes.map((post) => (
          <BlogpostSummary key={post?.id} Element="li" {...post} />
        ))}
      </UnstyledBlockUl>
    </Layout>
  )
}

export const categoryPageData = graphql`
  query {
    allSanityPost(
      filter: {
        slug: { current: { ne: null } }
        isFuture: { eq: false }
        publishedAt: { ne: null }
      }
      sort: { fields: [publishedAt], order: [DESC] }
    ) {
      group(field: categories___title) {
        fieldValue
        totalCount
        nodes {
          ...PostDataFragment
        }
      }
    }
  }
`
